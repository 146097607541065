import { default as indexGXoMqwRKLpMeta } from "C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/index.vue?macro=true";
import { default as _91id_93wfOCJynAbxMeta } from "C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/order/[id].vue?macro=true";
import { default as _91hash_93RrvRY79vRAMeta } from "C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue?macro=true";
import { default as waiting_45shippingZIaO3zFlajMeta } from "C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting-shipping.vue?macro=true";
import { default as waitingQGMnLIL1uwMeta } from "C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting.vue?macro=true";
import { default as component_45stubc6sCe3FRQQMeta } from "C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubc6sCe3FRQQ } from "C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index___en___default",
    path: "/",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/index.vue")
  },
  {
    name: "index___pt-BR",
    path: "/pt-BR",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/index.vue")
  },
  {
    name: "index___zh-CN",
    path: "/zh-CN",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/index.vue")
  },
  {
    name: "order-id___en___default",
    path: "/order/:id()",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/order/[id].vue")
  },
  {
    name: "order-id___en",
    path: "/en/order/:id()",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/order/[id].vue")
  },
  {
    name: "order-id___pt-BR",
    path: "/pt-BR/order/:id()",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/order/[id].vue")
  },
  {
    name: "order-id___it",
    path: "/it/order/:id()",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/order/[id].vue")
  },
  {
    name: "order-id___zh-CN",
    path: "/zh-CN/order/:id()",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/order/[id].vue")
  },
  {
    name: "thank-you-hash___en___default",
    path: "/thank-you/:hash()",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue")
  },
  {
    name: "thank-you-hash___en",
    path: "/en/thank-you/:hash()",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue")
  },
  {
    name: "thank-you-hash___pt-BR",
    path: "/pt-BR/thank-you/:hash()",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue")
  },
  {
    name: "thank-you-hash___it",
    path: "/it/thank-you/:hash()",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue")
  },
  {
    name: "thank-you-hash___zh-CN",
    path: "/zh-CN/thank-you/:hash()",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue")
  },
  {
    name: "waiting-shipping___en___default",
    path: "/waiting-shipping",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting-shipping.vue")
  },
  {
    name: "waiting-shipping___en",
    path: "/en/waiting-shipping",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting-shipping.vue")
  },
  {
    name: "waiting-shipping___pt-BR",
    path: "/pt-BR/waiting-shipping",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting-shipping.vue")
  },
  {
    name: "waiting-shipping___it",
    path: "/it/waiting-shipping",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting-shipping.vue")
  },
  {
    name: "waiting-shipping___zh-CN",
    path: "/zh-CN/waiting-shipping",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting-shipping.vue")
  },
  {
    name: "waiting___en___default",
    path: "/waiting",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting.vue")
  },
  {
    name: "waiting___en",
    path: "/en/waiting",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting.vue")
  },
  {
    name: "waiting___pt-BR",
    path: "/pt-BR/waiting",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting.vue")
  },
  {
    name: "waiting___it",
    path: "/it/waiting",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting.vue")
  },
  {
    name: "waiting___zh-CN",
    path: "/zh-CN/waiting",
    component: () => import("C:/Dev/Projetos/XMondo/a-arte-da-influencia-landing-page/pages/waiting.vue")
  },
  {
    name: component_45stubc6sCe3FRQQMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubc6sCe3FRQQ
  },
  {
    name: component_45stubc6sCe3FRQQMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubc6sCe3FRQQ
  },
  {
    name: component_45stubc6sCe3FRQQMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubc6sCe3FRQQ
  },
  {
    name: component_45stubc6sCe3FRQQMeta?.name,
    path: "/pt-br-sitemap.xml",
    component: component_45stubc6sCe3FRQQ
  },
  {
    name: component_45stubc6sCe3FRQQMeta?.name,
    path: "/it-sitemap.xml",
    component: component_45stubc6sCe3FRQQ
  },
  {
    name: component_45stubc6sCe3FRQQMeta?.name,
    path: "/zh-cn-sitemap.xml",
    component: component_45stubc6sCe3FRQQ
  }
]